@import '~@fortawesome/fontawesome-free/css/all.css';
@import url('https://fonts.googleapis.com/css?family=Khula&display=swap&.css');

/* Hide scrollbars but keep functionality*/
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbars but keep functionality*/
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
}
html::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Hide up and down arros on input type="number" */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide up and down arros on input type="number" */
/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

html,
body {
    min-width: 400px;
    min-height: 600px;
    max-width: 100%;
    overflow-x: hidden;
}

/* Hide scroll bars on antd modals*/
.ant-modal-wrap.ant-modal-centered::-webkit-scrollbar {
    display: none;
}

/* ITEMS BELOW TO BE MOVED TO STYLED COMPONENTS*/

/* useWallet.js, useBCH.js */
@media (max-width: 768px) {
    .ant-notification {
        width: 100%;
        top: 20px !important;
        max-width: unset;
        margin-right: 0;
    }
}
